<template>
  <div class="app-box">
    <van-tabs
      v-model="active"
      title-active-color="#0E8AFFFF"
      sticky
      animated
      color="#fff"
      title-inactive-color="#000"
      @click="onClick"
    >
      <van-tab
        v-for="(item, index) in list"
        :key="index"
        :title="item.title"
        :name="item.name"
      >
        <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            :immediate-check="false"
            @load="onLoad"
          >
            <div class="ulBox">
              <div class="bacg" v-if="dataArr.length">
                <div
                  class="bottomColor"
                  v-for="(item, index) in dataArr"
                  :key="index"
                >
                  <div class="li">
                    <div class="textColor">
                      <div class="textColor1" @click="Jupe(item.id)">
                        <van-icon name="plus" class="pushIcon" />加入我的团队
                      </div>
                    </div>
                    <!-- end -->
                    <div class="moelUle">
                      <div class="left">
                        <img
                          v-if="item.avatar_img"
                          :src="item.avatar_img"
                          alt=""
                        />
                        <img v-else src="../../assets/img/csUser.jpg" alt="" />
                        <div class="rightTtex">
                          <p class="listHeight">
                            <span class="nimcUser">昵称人</span
                            ><span>{{ item.nickname }}</span>
                          </p>
                          <p v-if="item.city">
                            签约城市:<span class="citytitle">{{
                              item.city
                            }}</span>
                          </p>
                          <p v-else>
                            签约城市:<span class="citytitle">无</span>
                          </p>
                        </div>
                      </div>
                      <div class="right">
                        <p v-if="item.pay_state == 1">已付款</p>
                        <p class="colorjg" v-else-if="item.pay_state == 2">
                          待付款
                        </p>
                        <p v-else-if="item.pay_state == 3">意向</p>
                        <p>¥{{ item.amount }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- end-Bottom -->
                  <div class="bottomFot">
                    <p class="doCCc">{{ item.update_date }}</p>
                    <p class="rightCCc">
                      预估佣金:¥{{ item.estimated_income }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="blank" v-else>
                <van-empty description="暂无数据" />
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { customersLists } from "@/request/api";
import { Notify } from "vant";
export default {
  data() {
    return {
      active: 0,
      dataArr: [], //全部list
      isLoading: false,
      loading: false,
      finished: false,
      list: [
        { title: "全部", name: "" },
        { title: "已付款", name: "1" },
        { title: "待付款", name: "2" },
        { title: "意向", name: "3" },
      ],
      page: {
        page: "1",
        size: "10",
        pay_state: "",
      },
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      customersLists(this.page).then((res) => {
        if (res.code != 1000) {
          return;
        }
        this.dataArr = res.data.lists.data;
      });
    },
    onClick(name) {
      this.page.pay_state = name;
      this.page.page = "1";
      this.getType();
    },
    getType() {
      customersLists(this.page).then((res) => {
        if (res.code != 1000) {
          this.dataArr = [];
          return;
        }
        this.dataArr = res.data.lists.data;
      });
    },
    //下拉刷新
    onRefresh() {
      this.page.page = "1"; //改回页数
      this.finished = false; //重置加载状态
      this.loading = false;
      setTimeout(() => {
        this.getData1();
      }, 1000);
    },
    getData1() {
      customersLists(this.page).then((res) => {
        if (res.code != 1000) {
          this.isLoading = false;
          return;
        }
        this.dataArr = res.data.lists.data;
        Notify({ type: "success", message: "刷新成功" });
        this.isLoading = false;
      });
    },
    //上拉加载
    onLoad() {
      setTimeout(() => {
        this.page.page++;
        customersLists(this.page).then((res) => {
          if (res.code != 1000) {
            this.finished = true;
            return;
          }
          this.dataArr = this.dataArr.concat(res.data.lists.data);
          // 加载状态结束
          this.loading = false;
        });
      }, 1000);
    },
    Jupe(id) {
      this.$router.push({ path: "/JoinOperation", query: { id: id } });
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
}
::v-deep .van-tabs__wrap {
  border-bottom: 1px solid #d0d2daff;
}
.ulBox .bacg {
  padding-bottom: 10px;
  box-sizing: border-box;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.ulBox .li {
  width: 95%;
  margin: auto;
  overflow: hidden;
  border-bottom: 1px solid #f3f3f5ff;
}
.ulBox .li .pushIcon {
  color: #0e8affff;
  vertical-align: middle;
  font-size: 15px;
}
.ulBox .li .textColor {
  overflow: hidden;
}
.ulBox .li .textColor1 {
  color: #0e8affff;
  background: #0e8aff12;
  padding: 2px 4px;
  border-radius: 5px;
  float: right;
  font-size: 16px;
}
.moelUle {
  display: flex;
  padding-bottom: 6px;
}
.moelUle .left {
  flex: 3;
  overflow: hidden;
}
.moelUle .left img {
  width: 50px;
  height: 50px;
  float: left;
  border-radius: 50%;
}
.moelUle .right {
  flex: 1;
  text-align: right;
  margin-top: 8px;
}
.rightTtex {
  font-size: 14px;
  margin-left: 55px;
  margin-top: 4px;
}
.rightTtex .nimcUser {
  font-size: 14px;
  font-weight: bold;
}
.moelUle .right .colorjg {
  color: #db2600ff;
  line-height: 22px;
}
.bottomFot {
  width: 95%;
  margin: auto;
  padding-top: 10px;
  overflow: hidden;
  display: flex;
}
.doCCc {
  color: #999dadff;
  font-size: 15px;
  flex: 2.5;
  line-height: 30px;
}
.rightCCc {
  font-size: 15px;
  flex: 3;
  text-align: right;
  line-height: 30px;
}
.citytitle {
  margin-left: 5px;
}
.bottomColor {
  border-bottom: 5px solid #edf2f5ff;
  background: #fff;
  padding: 7px 0;
}
::v-deep .van-nav-bar .van-icon {
  color: #000;
}
</style>